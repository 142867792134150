import { Button, Stack, Box, Hidden, Header } from '@angellist/adapt';
import React, { ReactNode } from 'react';

interface Props {
  title?: string;
  subtitle?: string;
  onClose: () => void;
  headerActions?: ReactNode;
}

const SlidingPaneHeader = (props: Props) => {
  const { title, subtitle, onClose, headerActions } = props;
  const hasTitle = title;

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="150"
      paddingTop={{ xs: '150', sm: '200' }}
      paddingX={{ xs: '150', sm: '200' }}
      position="relative"
    >
      <Stack direction="horizontal" justify="space-between">
        <Box style={{ marginLeft: '-10px' }}>
          <Button
            aria-label="close"
            onPress={onClose}
            icon="x"
            variant="ghost"
          />
        </Box>
        <Hidden below="sm">{headerActions && headerActions}</Hidden>
      </Stack>
      {hasTitle && <Header description={subtitle}>{title}</Header>}
    </Box>
  );
};

export default SlidingPaneHeader;
