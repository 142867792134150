import { useRef, useState } from 'react';

const useAdaptPane = (defaultProps: any = {}) => {
  const cacheKeyRef = useRef(0);
  const timerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [props, setProps] = useState(defaultProps);

  const onOpen = (newProps = {}) => {
    if (timerRef?.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    setProps(newProps);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    timerRef.current = setTimeout(() => {
      // resetting after 3 sec
      setProps(defaultProps);
      cacheKeyRef.current += 1;
    }, 3000);
  };

  return {
    isOpen: open,
    cacheKey: cacheKeyRef.current,
    paneProps: props,
    onOpen,
    onClose,
  };
};

export default useAdaptPane;
