import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactSlidingPane from 'react-sliding-pane';
import SlidingPaneHeader from './SlidingPaneHeader';

const SIZES = {
  xs: 480,
  sm: 640,
  md: 800,
};

export const useSlidingPaneWidth = (size) => {
  const defaultWidth = SIZES[size || 'md'];
  const [width, setWidth] = useState(`${defaultWidth}px`);

  useLayoutEffect(() => {
    function updateWidth() {
      if (window.innerWidth <= 600) {
        setWidth('100%');
      } else if (window.innerWidth <= defaultWidth) {
        setWidth('80%');
      } else {
        setWidth(`${defaultWidth}px`);
      }
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  return width;
};

const SlidingPane = (props) => {
  const {
    children,
    isOpen,
    title,
    subtitle,
    closeSlidingPane,
    headerActions,
    size,
    customClasses,
    ...restProps
  } = props;

  const width = useSlidingPaneWidth(size);

  return (
    <ReactSlidingPane
      isOpen={isOpen}
      width={width}
      hideHeader
      className="sliding-pane"
      overlayClassName={`sliding-pane-overlay ${customClasses}`}
      onRequestClose={closeSlidingPane}
      {...restProps}
    >
      <SlidingPaneHeader
        title={title}
        subtitle={subtitle}
        onClose={closeSlidingPane}
        headerActions={headerActions}
      />
      <div className="sliding-pane-content-wrap">{children}</div>
    </ReactSlidingPane>
  );
};

SlidingPane.defaultProps = {
  isOpen: false,
  size: 'md',
  title: null,
  subtitle: null,
  headerActions: null,
  customClasses: '',
};

SlidingPane.propTypes = {
  isOpen: PropTypes.bool,
  customClasses: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md']),
  closeSlidingPane: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  headerActions: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default SlidingPane;
